import React, { useContext } from "react";
import { Block } from "baseui/block";
import { Heading, HeadingLevel } from 'baseui/heading';
import { ParagraphXSmall, LabelSmall } from 'baseui/typography';
import { ParishContext } from "../Map/parishProvider";

function PrivacidadeContent() {
  const { Name } = useContext(ParishContext);

  return (
    <React.Fragment>
      <Block paddingRight={"25px"}>
        <div className="privacy">
          <HeadingLevel>
            <Heading styleLevel={6}>Contrato de Privacidade e RGPD da {Name}</Heading>
          </HeadingLevel>
          <ParagraphXSmall style={{ textAlign: "justify" }}>No dia 25 de maio de 2018 entrou em vigor o novo Regulamento Geral sobre a Proteção de Dados Pessoais – Regulamento nº 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, que estabelece as regras relativas à proteção, tratamento e livre circulação dos dados pessoais das pessoas singulares e que se aplica diretamente a todas as entidades que procedam ao tratamento desses dados, em qualquer Estado membro da União Europeia, incluindo Portugal.</ParagraphXSmall>
          <ParagraphXSmall>O nosso objetivo é dar-lhe a conhecer as novas regras aplicáveis ao tratamento dos seus dados pessoais, os seus direitos e de que forma como pode os pode gerir.</ParagraphXSmall>
          <LabelSmall>Informação recolhida</LabelSmall>
          <ParagraphXSmall style={{ textAlign: "justify" }} l>São recolhidas as seguintes informações sobre o utilizador quando usa a aplicação:</ParagraphXSmall>
          <ul style={{ paddingLeft: "15px" }}>
            <li>Informações de uso: Recolhemos informações sobre como o utilizador usa a aplicação, e as ações que realiza com a mesma.</li>
            <li>Informações do equipamento: Recolhemos informações sobre o dispositivo que o utilizador utiliza ao aceder à aplicação, incluindo o modelo do dispositivo, o sistema operacional e a versão do software.</li>
            <li>Informações de localização: Se optar por compartilhar sua localização, podemos recolher informações sobre sua localização geográfica.</li>
          </ul>
          <ParagraphXSmall style={{ textAlign: "justify" }}>Para além das situações em que tratamos dados para cumprimento de imposições legais aplicáveis, tratamos os seus dados para as seguintes finalidades:</ParagraphXSmall>
          <ul style={{ paddingLeft: "15px" }}>
            <li>Prestação de serviços – Sempre que necessário, para lhe prestarmos os nossos serviços, tratamos os seus dados pessoais.</li>
            <li>Efeitos de campanhas Informativas – Os seus dados pessoais são igualmente tratados, para fins de campanhas Informativas, divulgação de eventos, noticias e alertas.</li>
          </ul>
          <LabelSmall>Informações partilhadas</LabelSmall>
          <ParagraphXSmall style={{ textAlign: "justify" }}>Nenhuma das informações recolhidas serão utilizadas para fins comerciais. No entanto podem ser partilhadas com as seguintes partes:</ParagraphXSmall>
          <ul style={{ paddingLeft: "15px" }}>
            <li>Fornecedores de serviço: Podem ser compartilhadas em situação de assistência técnica.</li>
            <li>Parceiros de publicidade: Podem ser compartilhar com parceiros de publicidade para fins de marketing e publicidade.</li>
            <li> Autoridades legais: Podem ser compartilhar com as autoridades legais se for necessário para cumprir com obrigações legais.</li>
            <li> A todo momento é garantido o direito de acesso aos seus dados pessoais, bem como, dentro dos limites do contratualizado e do Regulamento, de os alterar, opor-se ao respetivo tratamento, decidir sobre o tratamento automatizado dos mesmos, retirar o consentimento e exercer os demais direitos previstos na lei (salvo quanto aos dados que são indispensáveis à prestação dos serviços pela Junta de Freguesia, portanto de fornecimento obrigatório, ou ao cumprimento de obrigações legais a que a Junta de Freguesia esteja sujeita).</li>
          </ul>
          <ParagraphXSmall style={{ textAlign: "justify" }}>Estamos empenhados na proteção e confidencialidade dos seus dados pessoais. Tomámos as medidas técnicas e organizativas necessárias ao cumprimento do Regulamento, garantindo que o tratamento dos seus dados pessoais é lícito, leal, transparente e limitado às finalidades autorizadas. Adotámos as medidas que consideramos adequadas para assegurar a exatidão, integridade e confidencialidade dos seus dados pessoais, bem como todos os demais direitos que lhe assistem.</ParagraphXSmall>
        </div>
      </Block>
    </React.Fragment>
  );
}

export default PrivacidadeContent;
