import React, { useEffect, useRef, useContext, useState } from "react";
import { ParishContext } from "./parishProvider";
import { MapContext } from "./mapProvider";
import Dashboard from "../Dashboard";
import { getParishLimits } from "../../services/api";
import { isDashboard } from "../../services/helpers";
import { createDraggableMarker, getGeoLocation, initializeMap, setMapArea } from "../../services/maps";
import { InitAutoComplete } from "../../services/addresses";

function Map({ zoom }) {
  const parish = useContext(ParishContext)
  const { map, } = useContext(MapContext);
  const ref = useRef(null);
  const [isAdmin,] = useState(isDashboard());
  const [limites, setLimites] = useState(null);

  useEffect(() => {
    if (parish.STAPE) {
      getParishLimits(parish.STAPE)
        .catch(err => console.error(err))
        .then(coordinates => {
          var json = JSON.parse(coordinates);
          setLimites(typeof json.coordinates[0] !== "string" ? json.coordinates[0] : []);
        });
    }
  }, [parish.STAPE]);

  useEffect(() => {

    if (limites) {
      if (ref.current && !map) {
        initializeMap(ref.current, parish.LatLng, zoom, () => {
          InitAutoComplete(limites);

          window.google.maps.event.addListener(window.map, 'click', (event) => {
            createDraggableMarker(limites, event.latLng.lat(), event.latLng.lng());
          });

          getGeoLocation()
            .catch(err => console.log(err.message))
            .then(latLng => {
              setTimeout(() => createDraggableMarker(limites, latLng.lat, latLng.lng), 2000);
            });
        });
      }
    }

  }, [zoom, parish, limites, map]);

  useEffect(() => {
    setMapArea(limites);
  }, [limites]);


  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        {isAdmin ? <Dashboard limites={limites} /> : <></>}
        <div id="map" className="map" ref={ref}></div>
      </div>
    </React.Fragment>

  );
}

export default Map;
