import React from "react";
import { useStyletron } from "baseui";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Hide from "baseui/icon/hide";


function NotFound() {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ alignItems: "center", display: "flex", flexDirection: "column" })}>
      <Hide size={theme.sizing.scale1600} />
      <div className={css({ ...theme.typography.HeadingXSmall, paddingBlockStart: theme.sizing.scale650, paddingBlockEnd: theme.sizing.scale500 })}>Atenção</div>
      <div className={css({ ...theme.typography.ParagraphMedium, paddingBlockEnd: theme.sizing.scale650 })}>
        Não tem permissões para ver este módulo.
      </div>
      <Button onClick={() => { window.location.href = "/" }} kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact}>Retroceder</Button>
    </div>
  )
}

export default NotFound;