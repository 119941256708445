import React, { useContext, useEffect, useState } from "react";
import { KIND } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import { StyledLink } from "baseui/link";
import { StatefulDataTable, StringColumn, NumericalColumn } from "baseui/data-table";
import { api } from "../../services/api";
import { ParishContext } from "../Map/parishProvider";

function ObservatorioLocal() {
  const STAPE = useContext(ParishContext).STAPE;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    StringColumn({ title: "Nome da Rua", mapDataToValue: (data) => data.Name }),
    NumericalColumn({ title: "- 30 dias", mapDataToValue: (data) => data.Less_than_30 }),
    NumericalColumn({ title: "+ 30 dias", mapDataToValue: (data) => data.More_than_30 }),
    NumericalColumn({ title: "Por Resolver", mapDataToValue: (data) => data.Unsolved }),
    NumericalColumn({ title: "Fechados", mapDataToValue: (data) => data.Solved })
  ];

  useEffect(() => {
    if (STAPE)
      api.get(`/street/getTotalByStreet?stape=${STAPE}`)
        .catch((err) => console.log("Error: " + err.message))
        .then((response) => {
          const _data = response.data.map((d, i) => {
            return { id: i.toString(), data: d }
          });

          setData(_data);
        });
  }, [STAPE]);

  function handleOpenModalButtonClick() {
    setIsOpen(true);
  }

  function handleCloseModalButtonClick() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <StyledLink onClick={() => handleOpenModalButtonClick()}>Observatório Local</StyledLink>
      <Modal
        onClose={() => handleCloseModalButtonClick(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.auto}
        role={ROLE.dialog}
      >
        <ModalHeader>Observatório Local</ModalHeader>
        <ModalBody>
          <div style={{ width: "860px", height: "400px" }}>
            {!data ? <></> :
              <React.Fragment>
                <StatefulDataTable
                  columns={columns}
                  rows={data}
                  emptyMessage={() => (<span>Não foram encontrados registos para esta freguesia.</span>)}
                />
              </React.Fragment>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={KIND.tertiary} onClick={() => handleCloseModalButtonClick()}>Fechar</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ObservatorioLocal;