import React from "react";
import Navbar from "../../components/Navbar";
import Centered from "../../components/Centered";
import MapWrapper from "../../components/MapWrapper";
import { ParishProvider } from "../../components/Map/parishProvider";

function Home() {
  return (
    <ParishProvider>
      <Navbar />
      <Centered>
        <MapWrapper />        
      </Centered>
    </ParishProvider>
  );
}

export default Home;
