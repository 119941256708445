import React, { createContext, useState, useEffect } from "react";
import { getParishInfo } from '../../services/api';
import { geocodeAdrToLatLng } from '../../services/addresses';

const ParishContext = createContext();

function ParishProvider({ children }) {
  const [parish, setParish] = useState({});

  useEffect(() => {
    getParishInfo()
      .catch((err) => { console.error(err); })
      .then((data) => {
        geocodeAdrToLatLng((data.Address || "") +  ", " + data.Location)
          .catch((err) => { console.error(err); })
          .then((location) => {
            setParish({
              STAPE: data.STAPE,
              Name: data.Name,
              Address: data.Address,
              LatLng: location
            });
          });
      });
  }, []);

  return (
    <ParishContext.Provider value={parish}>
      {children}
    </ParishContext.Provider>
  )
}

export { ParishContext, ParishProvider }