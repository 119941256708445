function isInsidePolygon(latLngs, lat, lng) {
  if ((latLngs || []).length > 0) {
    if (!latLngs[0].lat) {
      latLngs = convertPolygon(latLngs);
    }
  }

  let vertices_y = [];
  let vertices_x = [];
  let longitude_x = lng;
  let latitude_y = lat;

  var r = 0;
  var i = 0;
  var j = 0;
  var c = 0;
  var point = 0;

  for (r = 0; r < latLngs.length; r++) {
    vertices_y.push(latLngs[r].lat);
    vertices_x.push(latLngs[r].lng);
  }

  let points_polygon = vertices_x.length;

  for (i = 0, j = points_polygon; i < points_polygon; j = i++) {
    point = i;

    if (point === points_polygon) {
      point = 0;
    }

    if ((vertices_y[point] > latitude_y) !== (vertices_y[j] > latitude_y) && longitude_x < ((vertices_x[j] - vertices_x[point]) * (latitude_y - vertices_y[point])) / (vertices_y[j] - vertices_y[point]) + vertices_x[point]
    ) {
      c = !c;
    }
  }

  return c;
}

function convertPolygon(polygon) {
  return (polygon || []).map((a) => {
    return {
      lat: a[1],
      lng: a[0]
    }
  })
}

export { isInsidePolygon }