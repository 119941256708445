import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { getCompetencies, createGradientBackgroundButtons } from "../../services/dashboard";

function CompetenceList({ data, state, year, updateCompetence }) {
  const [competencies, setCompetencies] = useState([]);

  useEffect(() => {
    const _data = (state === "" ? data : data.filter(x => x.State === state));
    let _competencies = getCompetencies(_data, year);
    if (_competencies.length <= 1) _competencies = [];
    setCompetencies(_competencies);
  }, [data, state, year]);

  function handleCompetenceButtonClick(e) {
    updateCompetence(e.target.dataset.comp);
  }

  return (
    <React.Fragment>
      {
        competencies.map((item, i) => {
          return (
            <button key={item.Description} type="button" data-cat={state} data-comp={item.Description} onClick={(e) => handleCompetenceButtonClick(e)} className="btn btn-style text-align-left-btn cpt competence" style={{ backgroundColor: createGradientBackgroundButtons(i), minWidth: '240px' }}>
              <i style={{ paddingRight: '10px' }}><FontAwesomeIcon icon={faInbox} /></i>{item.Description} <span style={{ marginLeft: "15px", float: 'right' }}>{item.Count}</span>
            </button>
          );
        })
      }
    </React.Fragment>
  )
}

export default CompetenceList;