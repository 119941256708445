import React, { useState } from "react";
import { FileUploader } from "baseui/file-uploader";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

function Upload({ updateImages, ...props }) {
  const [images, setImages] = useState(props.defaultImages);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <React.Fragment>
      <FileUploader
        name="myFile"
        multiple={true}
        errorMessage={errorMessage}
        accept={['.pdf', '.png', '.jpg', '.jpeg', '.doc', '.docx']}
        maxSize={59000000}
        onDrop={(acceptedFiles, rejectedFiles) => {
          let _images = images.concat(acceptedFiles);
          setImages(_images);
          updateImages(_images);

          if (rejectedFiles.length > 0) {
            setErrorMessage("Ocorreu um erro no envio.")
          }
        }}
      />
      <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale100" flexGridRowGap="scale100" style={{ marginTop: "15px" }}>
        {images.length === 0 ? <></> :
          images.map((imageObj, i) => {
            return (
              <FlexGridItem key={i}>
                <div><span>{imageObj.name ?? "-"}</span></div>
              </FlexGridItem>
            );
          })
        }
      </FlexGrid>
    </React.Fragment>
  )
}

export default Upload;