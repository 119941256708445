import { api, auth, getBaseURL } from './api';
import { is_aminharua } from './helpers';

function getPhotos(id) {
  const promise = new Promise((resolve, reject) => {
    api.get(`/ocorrencies/getPhotos?user=0&id=${id}`)
      .catch(err => reject(err))
      .then((response) => {
        var photos = response.data;
        resolve(photos);
      });
  });

  return promise;
}

function getPhotosOVP(id) {
  const promise = new Promise((resolve, reject) => {
    api.get(`/POVP/getLicPhotos?id=${id}`, { ...auth })
      .catch((err) => { reject(err) })
      .then((response) => {
        let photos = response.data;
        resolve(photos);
      });
  });
  
  return promise;
}

function getPhotoUrl(photo) {
  return `${getBaseURL().replace("/api", "")}/Documents/${photo.Year}/${photo.Path}`;
}

function createSliderModalWindow(innerHTML) {
  const myModal = document.createElement("div");
  myModal.id = "myModal"
  myModal.classList.add("modal");
  myModal.innerHTML = `<span class="close cursor" onclick="closeModal()">&times;</span>`;

  const myModalContent = document.createElement("div");
  myModalContent.classList.add("modal-content")
  myModalContent.innerHTML = innerHTML;
  myModal.appendChild(myModalContent);

  return myModal;
}

function buildPhotoTable(id, element) {
  (is_aminharua() ? getPhotos(id) : getPhotosOVP(id))
    .catch(err => { console.log(err.message) })
    .then(photos => {
      if (photos.length > 0) {
        const rows = document.createElement("div");
        rows.classList.add("row");

        photos.forEach((photo, i) => {
          rows.innerHTML += `
            <div class="column">
              <img src="${getPhotoUrl(photo)}" onclick="openModal();currentSlide(${i + 1})" class="hover-shadow" alt="" style="width:100%" />
            </div>
          `;
        });

        element.innerHTML += rows.outerHTML;

        let myModalContent = "";

        photos.forEach((photo, i) => {
          myModalContent += `
            <div class="mySlides">
              <div class="numbertext">${i + 1} / ${photos.length}</div>
              <img src="${getPhotoUrl(photo)}" alt="" style="width:100%" />
            </div>
          `;
        });

        myModalContent += `
          <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
          <a class="next" onclick="plusSlides(1)">&#10095;</a>
    
          <div class="caption-container">
            <p id="caption"></p>
          </div>
        `;

        photos.forEach((photo, i) => {
          myModalContent += `
            <div class="column">
              <img class="demo" src="${getPhotoUrl(photo)}" onclick="currentSlide(${i + 1})" alt="" style="width:100%" />
            </div>
          `;
        });

        if (document.getElementById("myModal") != null) {
          document.body.removeChild(document.getElementById("myModal"));
        }

        document.body.appendChild(createSliderModalWindow(myModalContent));
      }
    });
}

export { getPhotos, buildPhotoTable } 