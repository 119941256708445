import axios from 'axios';
import { SlowBuffer } from "buffer";
import { is_popvp } from './helpers';

const isDebug = (window.location.hostname.split(".")[0] === "localhost");
const debugApp = "aminharua";

const creds = getCreds();
const auth = {
  auth: {
    username: creds.user,
    password: creds.key,
  }
}

function getCreds() {
  var user = SlowBuffer('RnJlc29mdEFwaUtleQ==', 'base64').toString('ascii');
  var password = SlowBuffer('ab54jfkk-dd2d-4de0-423d-g999eb93s88s', 'base64').toString('ascii');
  return { user: user, key: password };
}

const api = axios.create({
  baseURL: getBaseURL(),
  responseType: 'json',
  validateStatus: function (status) {
    return status === 200;
  }
});

// Get the app base url
function getBaseURL() {
  if (isDebug) {
    return is_popvp() ? "http://localhost:53180/api" : "http://localhost:62911/api";
  } else {
    if (is_popvp()) {
      return "//apifresoft." + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/api";
    } else {
      return "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/api";
    }
  }
}


function getParishInfo() {
  const promise = new Promise((resolve, reject) => {
    api.get("/getConfApp")
      .catch(err => reject(err))
      .then(response => {
        if (Array.isArray(response.data)) {
          resolve(response.data[0]);
        } else {
          resolve(response.data);
        }      
      });
  });

  return promise;
}

function getParishByLatLng(lat, lng) {
  const promise = new Promise((resolve, reject) => {
    api.get(`https://aminharua2.fresoft.pt/api/street/getParishByLatLng?Lat=${lat}&Lng=${lng}`)
      .catch(err => reject(err))
      .then(response => {
        resolve({
          STAPE: response.data[0].dicofre
        });
      });
  });

  return promise;
}

function getParishLimits(STAPE) {
  const promise = new Promise((resolve, reject) => {
    api.get(`https://aminharua2.fresoft.pt/api/street/getParishLimits?Dicofre=${STAPE}`)
      .catch(err => reject(err))
      .then(response => {
        resolve(response.data.json);
      });
  });

  return promise;
}

function getOVPLicByID(id) {
  const promise = new Promise((resolve, reject) => {
    api.get(`/POVP/getLicInfo?id=${id}`, { ...auth })
      .catch(err => { reject(err) })
      .then(response => {
        let data = response.data;
        resolve(data);
      })
  });
  return promise;
}


export { api, auth, isDebug, debugApp, getParishLimits, getParishByLatLng, getParishInfo, getBaseURL, getOVPLicByID }