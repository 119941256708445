import { api } from "./api";
import { createDraggableMarker } from './maps';
import { isInsidePolygon } from './math';

function InitAutoComplete(limites) {
  var searcher = document.getElementById("searcher");
  searcher.focus();

  window.autoCompleteInput = new window.google.maps.places.Autocomplete(searcher, {
    componentRestrictions: { country: ["pt"] },
    fields: [
      "formatted_address",
      "geometry",
      "name"
    ],
    types: ["address"],
    strictBounds: true
  });

  if (limites) {
    var bounds = new window.google.maps.LatLngBounds(undefined, undefined, undefined, undefined);
    limites.forEach(x => bounds.extend({ lat: parseFloat(x[1]), lng: parseFloat(x[0]) }));
    window.autoCompleteInput.setBounds(bounds);
  }

  window.autoCompleteInput.addListener("place_changed", () => {
    var place = window.autoCompleteInput.getPlace();

    if (!place.geometry || !place.geometry.location) {
      alert("Não estão disponiveis detalhes para esta localização.");
      return;
    }

    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();

    if (!isInsidePolygon(limites, lat, lng)) {
      alert("Não estão disponiveis detalhes para localização.\n\nEsta é uma localização que provavelmente se intercepte com os limites da freguesia mas que está fora desses limites. Melhore a sua pesquisa para obter uma localização dentro da freguesia.\n\nTente adicionar um nº de porta.");
      return;
    }

    if (typeof place.geometry.viewport !== "undefined") {
      window.map.fitBounds(place.geometry.viewport);
    } else {
      window.map.setCenter(place.geometry.location);
      window.map.setZoom(16);
    }

    createDraggableMarker(limites, lat, lng);
  });
}

function geocodeFromLatLngToAdr(lat, lng) {
  const promise = new Promise((resolve, reject) => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
      location: new window.google.maps.LatLng(lat, lng),
    }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          resolve(results[0]);
        } else {
          reject(new Error("Localização desconhecida."));
        }
      } else {
        if (status === "OVER_QUERY_LIMIT") {
          reject(new Error("Demasiados pedidos em simultâneo. Aguarde alguns segundos e tente novamente."));
        } else {
          reject(new Error("O Geocoder falhou devido a um problema: " + status));
        }
      }
    });
  });

  return promise;
}


function geocodeAdrToLatLng(addr) {
  const promise = new Promise((resolve, reject) => {
    api.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDmGMREGfKSIFk8HGqdMXzT4lXI7iKNqRk&address=${encodeURIComponent(addr)}`)
      .catch((err) => reject(err))
      .then((response) => {
        switch(response.status) {
          case "ZERO_RESULTS":
            reject(new Error("ZERO_RESULTS"));
            break;
          default:
            if (response.data.results.length === 0) {
              reject(new Error("ZERO_RESULTS"))
            } else {
              resolve(response.data.results[0].geometry.location);
            }
            
            break;
        }
      });
  });

  return promise;
}

export { InitAutoComplete, geocodeFromLatLngToAdr, geocodeAdrToLatLng }