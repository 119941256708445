import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBoxArchive } from "@fortawesome/free-solid-svg-icons";
import { getStates, getElementByState, getStateColor } from "../../services/dashboard";

function StateContainer({ data, year, updateState, updateCompetence }) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    const _states = getStates(data, year);
    setStates(_states);
  }, [data, year]);

  function handleClearButtonClick(e) {
    updateState("");
    updateCompetence("");
  }

  function handleStateButtonClick(e) {
    updateState(e.target.dataset.cat);
    updateCompetence("");
  }

  return (
    <React.Fragment>
      <button type="button" id="limpar-btn" className="btn-default limpar-btn ctg numbered" style={{ minWidth: '240px' }} onClick={(e) => handleClearButtonClick(e)}>
        <i style={{ paddingRight: '10px' }}><FontAwesomeIcon icon={faXmark} /></i>Limpar Filtros
      </button>
      {
        states.map(item => {
          return (
            <button key={item.Description} type="button" className={"btn ctg numbered " + getElementByState(item.Description)} style={{ backgroundColor: getStateColor(item.Description), minWidth: '240px' }} data-cat={item.Description} data-comp="" data-year={year} onClick={(e) => handleStateButtonClick(e)}><i style={{ paddingRight: '10px' }}><FontAwesomeIcon icon={faBoxArchive} /></i>{item.Description}<span style={{ marginLeft: "15px", float: 'right' }}>{item.Count}</span></button>
          )
        })
      }
    </React.Fragment>
  )
}

export default StateContainer