import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { geocodeFromLatLngToAdr } from './addresses';
import { isInsidePolygon } from './math';

function initializeMap(mapDiv, center, zoomLevel, onTilesLoaded) {
  window.infoWindow = new window.google.maps.InfoWindow({
    content: "",
  });

  window.map = new window.google.maps.Map(mapDiv, {
    mapTypeId: "roadmap",
    center: center,
    zoom: zoomLevel ?? 14,
    minZoom: 10,
    maxZoom: 18,
    styles: removePOI(),
    disableDefaultUI: false,
    streetViewControl: false,
    mapTypeControl: false,
    navigationControl: true,
    fullscreenControl: false,
    clickableIcons: false,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    },
    navigationControlOptions: {
      style: window.google.maps.NavigationControlStyle.SMALL,
    },
  });

  setNightLight(window.map);

  window.markerCluster = new MarkerClusterer({ markers: [], map: window.map });

  document.addEventListener('keydown', function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);

    if (code === 27) {
      if (window.infoWindow &&  window.infoWindow.hide)
        window.infoWindow.hide();
      window.closeModal();
    }
  });

  if (onTilesLoaded)
    window.google.maps.event.addListenerOnce(window.map, 'tilesloaded', onTilesLoaded);

}

function removePOI() {
  return [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    }, {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    }, {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];
}


function darkMode() {
  return [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];
}


function getNightLight() {
  var d = new Date();
  return (d.getHours() >= 18 || d.getHours() < 8);
}

function setNightLight(map) {
  if (getNightLight()) {
    map.setOptions({ styles: removePOI().concat(darkMode()) });
  }
}

function pinSymbol(color) {
  return {
    path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
    fillColor: color,
    fillOpacity: 1,
    strokeColor: "#000",
    strokeWeight: 2,
    scale: 1,
  };
}

function getGeoLocation() {
  const promise = new Promise((resolve, reject) => {
    if (!window.navigator.geolocation) {
      reject(new Error("This browser not supports geolocation."));
    } else {
      window.navigator.geolocation.getCurrentPosition(position => {
        let pos = position.coords;
        resolve({ lat: pos.latitude, lng: pos.longitude });
      });
    }
  });

  return promise;
}

function setMapArea(coordinates) {
  if (typeof coordinates === "string") {
    coordinates = JSON.parse(coordinates).coordinates[0];
  }

  if (coordinates === null || coordinates.length === 0) {
    return;
  }

  var geoJson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [0, 90],
              [180, 90],
              [180, -90],
              [0, -90],
              [-180, -90],
              [-180, 0],
              [-180, 90],
              [0, 90],
            ],
            coordinates,
          ],
        },
        properties: {},
      },
    ],
  };


  var fence = new window.google.maps.Data({
    map: window.map,
    style: {
      strokeWeight: 1,
      strokeColor: "#2a2a2a"
    }
  });

  fence.addGeoJson(geoJson);
}

function clearOverlays() {
  (window.markers ?? []).forEach((marker) => {
    marker.setMap(null);
  });

  window.markers = [];
}

function createDraggableMarker(fences, lat, lng) {
  if (!isInsidePolygon(fences, lat, lng)) {
    return;
  }

  clearOverlays();

  const pinColor = (getNightLight() ? "white" : "blue");

  var marker = new window.google.maps.Marker({
    position: { lat, lng },
    draggable: true,
    icon: pinSymbol(pinColor),
    fences: fences
  });

  marker.setVisible(true);
  marker.setMap(window.map);

  var searcher = document.getElementById("searcher");
  if (searcher) {
    geocodeFromLatLngToAdr(lat, lng)
      .catch(err => alert(err.message))
      .then(addr => {
        searcher.value = addr.formatted_address;
      });
  }

  marker.addListener("click", (event) => {
    if (!event) return;

    let lat = event.latLng.lat();
    let lng = event.latLng.lng();

    geocodeFromLatLngToAdr(lat, lng)
      .catch(err => alert(err.message))
      .then(addr => {
        window.infoWindow.setContent(`<div class="ocorrency">${addr.formatted_address}</div>`); 
        window.infoWindow.open(window.map, marker);
      });
  });

  marker.addListener('dragstart', (event) => {
    window.currentMakerPosition = event.latLng;
  });

  marker.addListener('dragend', (event) => {

    if (window.markers[0]) {
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();

      if (!isInsidePolygon(window.markers[0].fences, lat, lng)) {
        window.markers[0].setPosition(window.currentMakerPosition);
      }
    }
  });

  window.markers.push(marker);
}

export { getNightLight, removePOI, pinSymbol, initializeMap, getGeoLocation, setMapArea, createDraggableMarker };