import { api, auth, getOVPLicByID } from './api';
import { geocodeFromLatLngToAdr } from './addresses';
import { pinSymbol } from './maps';
import { is_aminharua, is_popvp, toDate } from './helpers';
import { buildPhotoTable } from './photos';

function getStateColor(state) {
  return (state.toLowerCase().trim() === "por resolver" || state.toLowerCase().trim() === "caducada" ? "red" : state.toLowerCase().trim() === "reencaminhado" || state.toLowerCase().trim() === "suspensa" ? "orange" : "green");
}

function getElementByState(state) {
  return state.toLowerCase().trim() === "por resolver" || state.toLowerCase().trim() === "caducada" ? "resolver-btn" : (state.toLowerCase().trim() === "reencaminhado" || state.toLowerCase().trim() === "suspensa" ? "reenc-btn" : "resolvidos-btn");
}

function getStates(data, year) {
  if (typeof year !== "undefined") {
    data = data.filter(d => d.Year === parseInt(year));
  }

  let _status = data
    .map(d => d.State)
    .filter((x, i, a) => a.indexOf(x) === i)
    .map(d => {
      return {
        Description: d,
        Count: data.filter((x) => x.State === d).length,
        Color: getStateColor(d)
      };
    });

  return _status;
}

function getCompetencies(data, year) {
  if (typeof year !== "undefined") {
    data = data.filter(d => d.Year === parseInt(year));
  }

  let _competencies = data
    .map(d => (d.Competency ? d.Competency.trim() : "Sem Competência"))
    .filter((x, i, a) => a.indexOf(x) === i)
    .map(d => {
      return {
        Description: d,
        Count: data.filter((x) => (x.Competency ? x.Competency.trim() : "Sem Competência") === d).length,
      };
    })
    .filter(x => x.Count > 0)
    .sort((a, b) => a.Description > b.Description);

  return _competencies;
}

function getMarkersFromAPI() {
  const promise = new Promise((resolve, reject) => {
    var url = "/ocorrencies2";
    var config = {};

    if (is_popvp()) {
      url = "/povp/getAll";
      config = { ...auth };
    }

    api.get(url, config)
      .catch((err) => reject(err))
      .then(response => {
        let data = response.data;
        data = data.map(d => {
          return {
            ...d,
            isVisible: d.State.toLowerCase() !== "fechado"
          }
        });

        window.data = data;
        resolve(window.data);
      });
  });

  return promise;
}

async function buildContentPOVP(data) {
  const stateColor = getStateColor(data.State);
  const lic = await getOVPLicByID(data.ID);

  const content = document.createElement("div");
  content.classList.add("license-details-povp");
  content.classList.add("license");
  content.classList.add(stateColor);

  content.innerHTML = `
    <div><strong>Estabelecimento:</strong> ${data.User ? data.User : "Sem Descrição"}</div>
    <div><strong>Morada:</strong> ${data.Place === ", -" ? "Sem Morada" : data.Place}</div>
    <div><strong>Data de Validade:</strong> ${toDate(data.Date)}</div>
    <table class="lic-table" cellspacing="2" cellpadding="2" border="0" style="width: 100%">
      <thead>
        <tr>
          <th style="width:50px;">Nº Lic.</th>
          <th style="width:90px;">Estado Fatura</th>
          <th>Descrição</th>
          <th style="width:80px;text-align:right;">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${data.ID ? data.ID : "Sem licença"}</td>
          <td>${data.Category ? data.Category : "Sem Estado"}</td>
          <td>${data.Description ? data.Description : "Sem Descrição"}</td>
          <td style="text-align:right;">${parseFloat(lic.ValorLic ?? 0) === 0 ? "0,00" : lic.ValorLic} €</td>
        </tr>
      </tbody>
    </table>
  `;

  buildPhotoTable(data.ID, content);
  return content;
}

async function buildContentAMR(data) {
  function getUrl() {
    var url = "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
    return url.replace("aminharua", "freonline");
  }

  var place = data.Place;

  if (!data.Place || data.Place === ", -") {
    place = await geocodeFromLatLngToAdr(data.Lat, data.Lng);

    if (place) {
      place = place.formatted_address;
    }
  }

  const content = document.createElement("div");

  content.classList.add("ocorrency");
  content.classList.add(getStateColor(data.State));
  content.innerHTML = `
    <div><a target="_blank" href=${getUrl()}/Modules/OcorrenciasForm.aspx?ID=${data.ID}>${data.ID}</a></div>
    <div>${place}</div>
    <div class="lowercase-text">${data.Title ? data.Title : "Sem Descrição"}</div>
  `;

  buildPhotoTable(data.ID, content);
  return content;
}

async function buildContent(data) {
  if (is_aminharua()) {
    return await buildContentAMR(data);
  } else if (is_popvp()) {
    return await buildContentPOVP(data);
  } else {
    return "";
  }
}

function createMaker(marker) {
  const color = getStateColor(marker.State);

  var marker1 = new window.google.maps.Marker({
    position: { lat: marker.Lat, lng: marker.Lng },
    icon: pinSymbol(color)
  });

  marker1.setMap(null);
  marker1.setVisible(true);

  marker1.addListener('click', () => {
    buildContent(marker)
      .catch(err => console.log(err.message))
      .then(content => {
        window.infoWindow.setContent(content);
        window.infoWindow.open(window.map, marker1);
      });
  });

  return marker1;
}

function createGradientBackgroundButtons(idx) {
  var blue = 71 + (idx * 10);
  return `rgba(49, 52, ${blue}, 1)`;
}

function filterMarkersByMultipleFilter(data, cat, comp, year) {
  const _cat = cat.toLowerCase().trim();
  const _comp = comp.toLowerCase().trim();
  let filtered = [];

  filtered = data.filter((d) => {
    if (_cat === "" && _comp === "") {
      return true;
    } else {
      var competency = (d.Competency ?? "Sem Competência").toLowerCase().trim();
      var category = d.State.toLowerCase().trim();

      if (_cat !== "" && _comp === "") {
        return category === _cat;
      } else if (_comp !== "" && _cat === "") {
        return competency === _comp;
      } else if (_comp !== "" && _cat !== "") {
        return category === _cat && competency === _comp;
      }
    }

    return false;
  }).filter((d) => {
    return d.Year === parseInt(year);
  });

  return filtered;
}

function clearOverlays(markerCluster) {
  if (window.dashboardMarkers) {
    for (var i = 0; i < window.dashboardMarkers.length; i++) {
      window.dashboardMarkers[i].setMap(null);
    }
  }

  window.dashboardMarkers = [];
  markerCluster.clearMarkers();
}

export { getStates, getCompetencies, getMarkersFromAPI, buildContent, filterMarkersByMultipleFilter, getStateColor, getElementByState, createGradientBackgroundButtons, createMaker, clearOverlays }

