import React, { useEffect, useState } from "react";
import StateContainer from "./StateContainer";
import CompetenceList from "./CompetenceList";
import { createMaker, getMarkersFromAPI, filterMarkersByMultipleFilter } from "../../services/dashboard";
import { getYears } from "../../services/helpers";
import { isInsidePolygon } from "../../services/math";

function Dashboard({ limites }) {
  const [state, setState] = useState("");
  const [competence, setCompetence] = useState("");
  const [year, setYear] = useState((new Date()).getFullYear());
  const [data, setData] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {

    if (limites) {
      getMarkersFromAPI()
        .catch((err) => console.log(err))
        .then((data) => {
          const _data = data.filter(d => isInsidePolygon(limites, d.Lat, d.Lng));
          setData(_data);
        });
    }

  }, [limites]);

  useEffect(() => {

    var _data = filterMarkersByMultipleFilter(data, state, competence, year);
    setMarkers(_data.map(marker => createMaker(marker)));

  }, [state, competence, year, data]);

  useEffect(() => {
    setTimeout(() => {
      window.markerCluster.clearMarkers();
      window.markerCluster.addMarkers(markers);
    }, 1000);
  }, [markers]);

  function handleChooseYear(e) {
    const inputYear = e.target.value;
    setYear(inputYear);
  }

  return (
    <React.Fragment>
      <div id="filterBar" className="filterBar numbered">
        <StateContainer data={data} year={year} updateState={setState} updateCompetence={setCompetence} />
        <CompetenceList data={data} state={state} year={year} updateCompetence={setCompetence} />
      </div>
      <div className="yearChooser_Holder">
        <label id="yearChooser_lbl" htmlFor="choosenYear">Ano a consultar</label>
        <select id="choosenYear" data-year={year} value={year} onChange={(e) => handleChooseYear(e)}>
          {getYears().map((item) => {
            return (<option key={item} value={item}>{item}</option>);
          })}
        </select>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;