import { createContext, useState } from "react";

const MapContext = createContext({
    map: window.map ?? null,
    setMap: () => { }
});

function MapProvider({ children }) {
    const [map, setMap] = useState(window.map);

    return (
        <MapContext.Provider value={{ map: map, setMap: setMap }}>
            {children}
        </MapContext.Provider>
    )
}

export { MapContext, MapProvider };