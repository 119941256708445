import React, { useState, useEffect, useContext } from 'react';
import { ParishContext } from '../Map/parishProvider';
import { api } from '../../services/api';

function ParishLogo() {
  const parish = useContext(ParishContext);
  const [logo, setLogo] = useState(undefined);

  useEffect(() => {
    api.get("getParishLogo").then((response) => {
      setLogo(response.data);
    });
  }, [logo]);

  return logo ? (
    <img src={"data:image/jpeg;base64," + logo} alt={parish.Name} title={parish.Name} aria-label={parish.Name} />
  ) : (
    <></>
  );
}

export default ParishLogo;
