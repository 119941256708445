import React, { useEffect, useState } from 'react';
import { Radio, ALIGN, RadioGroup } from "baseui/radio";
import { api } from '../../services/api';

function ClassificationList({ updateData, ...props }) {
  const [assunto, setAssunto] = useState(props.IDClassification);
  const [classifications, setClassifications] = useState([]);

  function handleClassificationChange(e) {
    let value = parseInt(e.target.value);
    setAssunto(value);
    updateData({ IDClassification: value });
  }

  useEffect(() => {
    api.get("/classifications")
      .catch(err => console.log(err.message))
      .then((response) => {
        setClassifications(response.data)
      });
  }, []);

  return (
    <React.Fragment>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <RadioGroup
          initialState={{ value: assunto }}
          value={assunto}
          name="AssuntoPicker"
          onChange={e => handleClassificationChange(e)}
          align={ALIGN.vertical}
        >
          {!classifications ? (
            <p>Ocorreu um erro ao carregar classificações.</p>
          ) : (
            classifications.map((item) => {
              return (
                <Radio key={item.IDClassification} value={item.IDClassification}>{item.Classification}</Radio>
              );
            })
          )}
        </RadioGroup>
      </div>
    </React.Fragment>
  )
}

export default ClassificationList;