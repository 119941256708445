import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "../Map";

function MapWrapper() {
  const render = (status) => {
    if (status === Status.LOADING) return "Loading...";
    if (status === Status.FAILURE) return "Error";
    return null;
  };

  return (
    <Wrapper apiKey={"AIzaSyDmGMREGfKSIFk8HGqdMXzT4lXI7iKNqRk"} version={"weekly"} language={"pt"} region="PT" render={render} libraries={["core", "places", "maps"]}>
      <Map zoom={14} style={{ flexGrow: "1", height: "100%" }} />
    </Wrapper>
  )
}

export default MapWrapper;