import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Centered from "../../components/Centered";
import MapWrapper from "../../components/MapWrapper";
import NotFound from "../../components/NotFound";
import { ParishProvider } from "../../components/Map/parishProvider";
import { isDashboard } from "../../services/helpers";

function Dashboard() {
  const [isAdmin,] = useState(isDashboard());

  return (
    <ParishProvider>
      {
        !isAdmin ? <NotFound /> :
          <React.Fragment>
            <Navbar />
            <Centered>
              <MapWrapper />
            </Centered>
          </React.Fragment>
      }
    </ParishProvider>
  );
}

export default Dashboard;
