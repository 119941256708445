import React, { useState } from "react";
import { HeaderNavigation, ALIGN, StyledNavigationList, StyledNavigationItem } from "baseui/header-navigation";
import CreateOcorrency from '../CreateOcorrency';
import ObservatorioLocal from '../ObservatorioLocal';
import ParishLogo from '../ParishLogo';
import Searcher from '../Searcher';
import { isDashboard, is_aminharua } from '../../services/helpers';

function Navbar() {
  const [isAdmin,] = useState(isDashboard() && is_aminharua());

  return (
    <HeaderNavigation>
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem>
          <ParishLogo />
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.right}>
        <StyledNavigationItem>
          { isAdmin ? <ObservatorioLocal /> : <></> }
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.center} />
      <StyledNavigationList $align={ALIGN.right}>
        <StyledNavigationItem style={{ width: '400px' }}>
          <Searcher />
        </StyledNavigationItem>
        <StyledNavigationItem>
          { is_aminharua() ? <CreateOcorrency /> : <></> }
        </StyledNavigationItem>
      </StyledNavigationList>
    </HeaderNavigation>
  );
}

export default Navbar;